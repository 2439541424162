import React, { useState, useEffect } from "react";
import DataTable from "../../../Templates/DataTable";
import api from "../../../../services/api";
import Loader from "../../../Templates/Loader";
import EODInventoryMonitoringRow from "./EODInventoryMonitoringRow";

const EODInventoryMonitoringTable = ({ client }) => {
  const [isLoading, setLoading] = useState(true);
  const [inventory, setInventory] = useState([]);
  const titles = ["Site", "Product Name", "Qty Remain", "Days Remain"];

  useEffect(() => {
    api
      .get(`/inventory/inventory_monitoring/?client_code=${client}`)
      .then(response => {
        setInventory(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [client]);

  return (
    <div>
      {isLoading && <Loader />}
      {inventory.length > 0 ? (
        <DataTable headerTitles={titles}>
          {inventory.map((item, index) => (
            <EODInventoryMonitoringRow item={item} index={index} />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
    </div>
  );
};

export default EODInventoryMonitoringTable;
