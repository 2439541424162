import React from "react";
import EODClinicRecommendationsTable from "./EODClinicRecommendationsTable";

const EODClinicRecommendations = ({ client }) => {
  return (
    <div>
      <EODClinicRecommendationsTable
        client={client}
        recommendation="Send home"
      />
      <EODClinicRecommendationsTable
        client={client}
        recommendation="Send to hospital"
      />
    </div>
  );
};

export default EODClinicRecommendations;
