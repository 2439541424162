import React from "react";
// import classNames from "classnames";

// import { unusualDispensing } from "../eod.module.scss";

const EODSurveillanceMonitoringSiteRow = ({ item, index }) => {
  let {
    clinicSite,
    chiefComplaint,
    siteLevelStatistics,
    // clientLevelStatistics,
  } = item;
  let {
    percentHeadcount,
    relativeChange,
    absoluteChange,
  } = siteLevelStatistics;

  return (
    <tr
      key={index}
      // className={classNames({
      //   [unusualDispensing]:
      //     parseFloat(percentHeadcount) >
      //     parseFloat(clientLevelStatistics.percentHeadcount) * 1.5,
      // })}
    >
      <td>{clinicSite}</td>
      <td>{chiefComplaint}</td>
      <td>{percentHeadcount}</td>
      <td>{relativeChange}</td>
      <td>{absoluteChange}</td>
    </tr>
  );
};

export default EODSurveillanceMonitoringSiteRow;
