import React from "react";
import classNames from "classnames";

import { unusualDispensing } from "../eod.module.scss";

const EODInventoryMonitoringRow = ({ item, index }) => {
  let { clinicSite, currentQuantity, daysLeft, productTitle } = item;

  return (
    <tr
      key={index}
      className={classNames({
        [unusualDispensing]: daysLeft < 7,
      })}
    >
      <td>{clinicSite}</td>
      <td>{productTitle}</td>
      <td>{currentQuantity}</td>
      <td>{daysLeft}</td>
    </tr>
  );
};

export default EODInventoryMonitoringRow;
