import React from "react";

import EODReportTable from "./EODReportTable";

const EODReports = ({ client }) => {
  return (
    <div className="columns">
      <div className="column is-6">
        <EODReportTable client={client} reportType="IR" />
      </div>
      <div className="column is-6">
        <EODReportTable client={client} reportType="ER" />
      </div>
    </div>
  );
};

export default EODReports;
