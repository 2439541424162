import React, { useState, useEffect } from "react";

import DataTable from "../../../Templates/DataTable";

import api from "../../../../services/api";
import { EODClinicRecommendationsRow } from "./EODClinicRecommendationsRow";

const EODClinicRecommendationsTable = ({ client, recommendation }) => {
  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    api
      .get(
        `/consultations/clinic_recommendations/?client_code=${client}&recommendation=${recommendation}`
      )
      .then(response => {
        setValues(response.data);
      });
  }, [client]);

  const setValues = consultation => {
    setConsultations(consultation);
  };

  const sentHomeTitles = ["Site", "Nurse/s on duty", "Number"];

  const sentToHospitalTitles = [
    "Site",
    "Date",
    "Chief Complaint",
    "Nurse/s on duty",
  ];

  let titles;

  if (recommendation === "Send home") titles = sentHomeTitles;
  else if (recommendation === "Send to hospital") titles = sentToHospitalTitles;

  return (
    <div className="mb-2">
      <h1 className="has-text-primary">
        {recommendation === "Send home"
          ? "NUMBER OF SENT HOME OVER THE PAST 7 DAYS"
          : "RUNNING LIST OF SENT TO HOSPITAL OVER THE PAST 7 DAYS"}
      </h1>
      {consultations.length > 0 ? (
        <DataTable headerTitles={titles}>
          {consultations.map((consultation, index) => (
            <EODClinicRecommendationsRow
              recommendation={recommendation}
              consultations={consultation}
              key={index}
            />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
    </div>
  );
};

export default EODClinicRecommendationsTable;
