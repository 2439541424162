import React from "react";
// import EODUnusualDispensingTable from "./EODUnusualDispensingTable";
import EODSurveillanceMonitoringTable from "./EODSurveillanceMonitoringTable";

const EODSurveillanceMonitoring = ({ client }) => {
  return (
    <div>
      <EODSurveillanceMonitoringTable client={client} type="relative" />
      <EODSurveillanceMonitoringTable client={client} type="absolute" />
      <p className="mt-3">
        <i>* Red text indicates prevalence >1.5x vs Client average</i>
      </p>
    </div>
  );
};

export default EODSurveillanceMonitoring;
