import React, { useEffect, useState, Fragment } from "react";

import api from "../../../services/api";
import Datalist from "../../Templates/Forms/Datalist";
import Panel from "../../Templates/Forms/Panel";
import {
  faProcedures,
  faAngry,
  faPills,
  faPrescription,
  faThermometerFull,
} from "@fortawesome/free-solid-svg-icons";
import EODReports from "./Reports/EODReports";
import EODUnusualDispensing from "./UnusualDispensingSection/EODUnusualDispensing";
import EODInventoryMonitoring from "./InventoryMonitoringSection/EODInventoryMonitoring";
import EODClinicRecommendations from "./ClinicRecommendations/EODClinicRecommendations";
import EODSurveillanceMonitoring from "./SurveillanceMonitoringSection/EODSurveillanceMonitoring";

const EOD = ({ client }) => {
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [displayValue, setDisplayValue] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    api.get("clients/?is_active=True").then(response =>
      setClientList(
        response.data.results.map(item => ({
          value: item.code,
          label: `${item.name} (${item.code})`,
        }))
      )
    );
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let index = clientList.findIndex(client => client.value === "SI");
      setSelectedClient(clientList[index].value);
      setDisplayValue(clientList[index]);
    }
  }, [clientList]);

  return (
    <Fragment>
      <div className="mb-4">
        <Datalist
          value={displayValue}
          name="clients"
          options={clientList}
          hasLabel
          label="Client"
          placeholder="Select client from the list..."
          onChange={selected => {
            setSelectedClient(selected.value);
            setDisplayValue(selected);
          }}
        />
      </div>
      <Panel heading="Incident Reports and Escalations" icon={faAngry}>
        <EODReports client={selectedClient} />
      </Panel>
      <Panel
        heading="Surveillance Monitoring - Past 7 Days"
        icon={faThermometerFull}
      >
        <EODSurveillanceMonitoring client={selectedClient} />
      </Panel>
      <Panel
        heading="Unusual Dispensing Patterns (>2x) - Past 7 Days"
        icon={faPrescription}
      >
        <EODUnusualDispensing client={selectedClient} />
      </Panel>
      <Panel heading="Clinic Recommendations" icon={faProcedures}>
        <EODClinicRecommendations client={selectedClient} />
      </Panel>
      <Panel heading="Inventory Monitoring" icon={faPills}>
        <EODInventoryMonitoring client={selectedClient} />
      </Panel>
    </Fragment>
  );
};

export default EOD;
