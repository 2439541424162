import React from "react";
import Layout from "../../layout/Layout";
import EOD from "../../components/Admin/EOD/EOD";
import SEO from "../../layout/Seo";

export default () => (
  <Layout pageTitle="EOW Report">
    <SEO title="EOW Report" />
    <EOD />
  </Layout>
);
