import React from "react";

const EODReportRow = ({ eodReport }) => {
  let { clinicName, primaryCategory, shortDescription } = eodReport;

  return (
    <tr>
      <td>{clinicName}</td>
      <td>{primaryCategory}</td>
      <td>{shortDescription}</td>
    </tr>
  );
};

export default EODReportRow;