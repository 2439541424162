import React, { useState, useEffect } from "react";
import moment from "moment";

import DataTable from "../../../Templates/DataTable";
import EODReportRow from "./EODReportRow";
import Loader from "../../../Templates/Loader";

import api from "../../../../services/api";

const ReportStatistics = ({
  oldestUnresolved,
  monthTotal,
  totalUnresolved,
  pendingWithMedgrocerToClient,
  pendingWithMedgrocerToStaff,
  pendingWithStaff,
}) => {
  const items = [
    {
      "Oldest unresolved": oldestUnresolved,
    },
    {
      "Month total": monthTotal,
    },
    {
      "Total unresolved": totalUnresolved,
    },
    {
      "Pending with MedGrocer to Client": pendingWithMedgrocerToClient,
    },
    {
      "Pending with MedGrocer to MG Staff": pendingWithMedgrocerToStaff,
    },
    {
      "Pending with MG Staff": pendingWithStaff,
    },
  ];

  return (
    <table className="table is-fullwidth">
      <tbody>
        {items.map(item => (
          <tr>
            <th>{Object.keys(item)}</th>
            <td>{Object.values(item)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const EODReportTable = ({ client, reportType }) => {
  const [incidentReports, setIncidentReports] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `/incident_reports/?ordering=date_sent_to_mg&client_code=${client}&report_type=${reportType}`
      )
      .then(response => {
        setValues(response.data.results, Math.ceil(response.data.count / 30));
      });
  }, [client]);

  const setValues = incidentReport => {
    setIncidentReports(incidentReport);
    setLoading(false);
  };

  const titles = ["Site", "Category", "Short Description"];
  const date = new Date();
  const dateToday = moment(date).format("YYYY-MM-DD");
  const currentMonth = moment(date).format("YYYY-MM");

  const reportedToday = incidentReports.filter(
    report => report.dateSentToMg.includes(dateToday) === true
  );

  const oldestUnresolved = incidentReports.filter(
    report => report.status !== "Ok"
  );

  const monthTotal = incidentReports.filter(
    report => report.dateSentToMg.includes(currentMonth) === true
  ).length;

  const totalUnresolved = incidentReports.filter(
    report => report.status !== "Ok"
  ).length;

  const pendingWithMedgrocerToClient = incidentReports.filter(
    report => report.status === "To draft letter"
  ).length;

  const pendingWithMedgrocerToStaff = incidentReports.filter(
    report => report.status === "To investigate"
  ).length;

  const pendingWithStaff = incidentReports.filter(
    report => report.status === "To follow-up with CHSI"
  ).length;

  return (
    <div>
      <h1 className="has-text-primary">
        {reportType === "IR" ? "INCIDENT REPORTS" : "ESCALATION REPORTS"}
      </h1>
      <ReportStatistics
        oldestUnresolved={
          oldestUnresolved.length > 0
            ? moment(oldestUnresolved[0].dateSentToMg).format("YYYY-MM-DD")
            : "None"
        }
        monthTotal={monthTotal}
        totalUnresolved={totalUnresolved}
        pendingWithMedgrocerToClient={pendingWithMedgrocerToClient}
        pendingWithMedgrocerToStaff={pendingWithMedgrocerToStaff}
        pendingWithStaff={pendingWithStaff}
      />
      <h1 className="has-text-primary">REPORTED TODAY</h1>
      {isLoading && <Loader />}
      {reportedToday.length > 0 ? (
        <DataTable headerTitles={titles}>
          {reportedToday.map((report, index) => (
            <EODReportRow eodReport={report} key={index} />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
    </div>
  );
};

export default EODReportTable;
