import React from "react";
import EODUnusualDispensingTable from "./EODUnusualDispensingTable";

const EODUnusualDispensing = ({ client }) => {
  return (
    <div>
      <EODUnusualDispensingTable client={client} />
      <p className="mt-3">
        <i>* Red text indicates more than 5x average daily consumption</i>
      </p>
    </div>
  );
};

export default EODUnusualDispensing;
