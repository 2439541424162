import React, { useState, useEffect } from "react";
import DataTable from "../../../Templates/DataTable";
import api from "../../../../services/api";
import EODUnusualDispensingRow from "./EODUnusualDispensingRow";
import Loader from "../../../Templates/Loader";

const EODUnusualDispensingTable = ({ client }) => {
  const [isLoading, setLoading] = useState(true);
  const [unusualProducts, setUnusualProducts] = useState([]);
  const titles = ["Site", "Product Name", "Consumed", "Ave. Daily"];

  useEffect(() => {
    api
      .get(`/inventory/unusual_dispensing_patterns/?client_code=${client}`)
      .then(response => {
        setUnusualProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [client]);

  return (
    <div>
      {isLoading && <Loader />}
      {unusualProducts.length > 0 ? (
        <DataTable headerTitles={titles}>
          {unusualProducts.map((item, index) => (
            <EODUnusualDispensingRow item={item} index={index} />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
    </div>
  );
};

export default EODUnusualDispensingTable;
