import React, { Fragment } from "react";
import moment from "moment";

export const EODClinicRecommendationsRow = ({
  consultations,
  recommendation,
}) => {
  let {
    clinicSite,
    chiefComplaint,
    clinicStaffName,
    created,
    count,
  } = consultations;

  return (
    <tr>
      <td>{clinicSite}</td>
      {recommendation === "Send to hospital" && (
        <Fragment>
          <td>{moment(created).format("MMM DD, hh:MM A")}</td>
          <td>{chiefComplaint}</td>
        </Fragment>
      )}
      <td>{clinicStaffName}</td>
      {recommendation === "Send home" && <td>{count}</td>}
      {/* {recommendation === "Send to hospital" && clinicStaffType === "MD" && (
        <td>{clinicStaffName}</td>
      )} */}
    </tr>
  );
};

export default EODClinicRecommendationsRow;
