import React from "react";
import EODInventoryMonitoringTable from "./EODInventoryMonitoringTable";

const EODInventoryMonitoring = ({ client }) => {
  return (
    <div>
      <EODInventoryMonitoringTable client={client} />
      <p className="mt-3">
        <i>
          * Those indicated in red are those with less than 7 days' worth of
          inventory.
        </i>
      </p>
    </div>
  );
};

export default EODInventoryMonitoring;
