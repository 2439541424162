import React, { useState, useEffect } from "react";
import DataTable from "../../../Templates/DataTable";
import api from "../../../../services/api";
import Loader from "../../../Templates/Loader";
import EODSurveillanceMonitoringSiteRow from "./EODSurveillanceMonitoringSiteRow";
import EODSurveillanceMonitoringClientRow from "./EODSurveillanceMonitoringClientRow";

const EODSurveillanceMonitoringTable = ({ client, type }) => {
  const [isLoading, setLoading] = useState(true);
  const [highestList, setHighestList] = useState([]);
  const siteTitles = [
    "Site",
    "Chief Complaint",
    "% Headcount",
    "Relative Δ",
    "Absolute Δ",
  ];
  const clientTitles = ["% Headcount", "Relative Δ", "Absolute Δ"];

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `/consultations/surveillance_monitoring/highest_growth/?client_code=${client}&type=${type}`
      )
      .then(response => {
        setHighestList(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [client]);

  return (
    <div className="mb-3">
      <h1 className="has-text-primary is-uppercase">
        <u>TOP 5 HIGHEST {type} GROWTH</u>
      </h1>
      {isLoading ? (
        <Loader />
      ) : highestList.length > 0 ? (
        <div className="columns">
          <div className="column">
            <DataTable headerTitles={siteTitles}>
              {highestList.map((item, index) => (
                <EODSurveillanceMonitoringSiteRow item={item} index={index} />
              ))}
            </DataTable>
          </div>
          <div className="column">
            <DataTable headerTitles={clientTitles}>
              {highestList.map((item, index) => (
                <EODSurveillanceMonitoringClientRow item={item} index={index} />
              ))}
            </DataTable>
          </div>
        </div>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
    </div>
  );
};

export default EODSurveillanceMonitoringTable;
