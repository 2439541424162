import React from "react";
import classNames from "classnames";

import { unusualDispensing } from "../eod.module.scss";

const EODUnusualDispensingRow = ({ item, index }) => {
  let { clinicSite, consumed, dailyAverage, productTitle } = item;

  return (
    <tr
      key={index}
      className={classNames({
        [unusualDispensing]: consumed > dailyAverage * 5,
      })}
    >
      <td>{clinicSite}</td>
      <td>{productTitle}</td>
      <td>{consumed}</td>
      <td>{dailyAverage}</td>
    </tr>
  );
};

export default EODUnusualDispensingRow;
